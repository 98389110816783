<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" :to="capability.organization.id ? '/':'/install/organizations'" v-if="capability">
      <div v-if="capability.user_profile.organization_logo">
        <CImg class="custom-limit-logo-size c-sidebar-brand-full" :src="capability.user_profile.organization_logo" />
      </div>
      <!-- <div v-else>
        <CImg class="custom-limit-logo-size-none c-sidebar-brand-full" src="/edgedx-brand3.png" />
        <CImg class="custom-limit-logo-size-min c-sidebar-brand-minimized" src="/edgedx-emb.png" />
      </div> -->
    </CSidebarBrand>
    <CSidebarNav>
      <CSidebarNavItem v-if="myAuthorizationLevel.level <= 100 && org" to="/dashboard" :name="$t('cloud.title.dashboard')" icon="cil-speedometer" />

      <CSidebarNavDropdown v-if="myAuthorizationLevel.level <= 100 && org" :name="$t('cloud.title.alarm')" icon="cil-bell" route="/alarm">
        <CSidebarNavItem to="/alarm/live" :name="$t('cloud.title.live_alarm')" />
        <CSidebarNavItem to="/alarm/search" :name="$t('cloud.title.alarm_search')" />
      </CSidebarNavDropdown>

      <CSidebarNavTitle v-if="is_any_app">{{$t('cloud.title.counting_apps')}}</CSidebarNavTitle>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('occupancy_counting')>=0" :name="$t('cloud.title.apps_occupancy')" icon="supervised_user_circle" route="/apps/occupancy">
        <CSidebarNavItem to="/apps/occupancy/analytics" :name="$t('cloud.title.vapp_occupancy')" />
        <CSidebarNavItem to="/apps/occupancy/settings" :name="$t('cloud.title.setting')" />
        <CSidebarNavItem href="/docs/occupancy/swagger/" name="API" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('people_counting')>=0" :name="$t('cloud.title.apps_people_counting')" icon="transfer_within_a_station" route="/apps/people_counting">
        <CSidebarNavItem to="/apps/people_counting/analytics" :name="$t('cloud.title.vapp_people_counting')" />
        <CSidebarNavItem to="/apps/people_counting/settings" :name="$t('cloud.title.setting')" />
        <CSidebarNavItem href="/docs/people_counting/swagger/" name="API" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('visit_advanced')>=0" :name="$t('cloud.title.apps_visit_advanced')" icon="wc" route="/apps/visit_advanced">
        <CSidebarNavItem to="/apps/visit_advanced/analytics" :name="$t('cloud.title.vapp_gender')" />
        <CSidebarNavItem to="/apps/visit_advanced/settings" :name="$t('cloud.title.setting')" />
        <CSidebarNavItem href="/docs/gender/swagger/" name="API" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('lpr_us')>=0" name="LPR-US" icon="cil-car-alt" route="/apps/lpr_us">
        <CSidebarNavItem to="/apps/lpr_us/lp_management" :name="$t('cloud.title.lp_management')" />
        <CSidebarNavItem to="/apps/lpr_us/devices" :name="$t('cloud.title.devices')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('lpr_eu')>=0" name="LPR-EU" icon="cil-car-alt" route="/apps/lpr_eu">
        <CSidebarNavItem to="/apps/lpr_eu/lp_management" :name="$t('cloud.title.lp_management')" />
        <CSidebarNavItem to="/apps/lpr_eu/devices" :name="$t('cloud.title.devices')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('lpr_jp')>=0" name="LPR-JP" icon="cil-car-alt" route="/apps/lpr_jp">
        <CSidebarNavItem to="/apps/lpr_jp/lp_management" :name="$t('cloud.title.lp_management')" />
        <CSidebarNavItem to="/apps/lpr_jp/devices" :name="$t('cloud.title.devices')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="apps_user_privilidge && apps.indexOf('lpr_kr')>=0" name="LPR-KR" icon="cil-car-alt" route="/apps/lpr_kr">
        <CSidebarNavItem to="/apps/lpr_kr/lp_management" :name="$t('cloud.title.lp_management')" />
        <CSidebarNavItem to="/apps/lpr_kr/devices" :name="$t('cloud.title.devices')" />
      </CSidebarNavDropdown>

      <CSidebarNavTitle v-if="myAuthorizationLevel.level <= 20">{{$t('cloud.title.system')}}</CSidebarNavTitle>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 20 && org"
        :name="$t('cloud.title.setting')" icon="cil-settings" route="/setup"
      >
        <CSidebarNavItem to="/setup/organization" :name="$t('cloud.title.organization')" />
        <CSidebarNavItem to="/setup/branches" :name="$t('cloud.title.branch')" />
        <CSidebarNavItem to="/setup/users" :name="$t('cloud.title.user')" />
        <CSidebarNavItem to="/setup/devices" :name="$t('cloud.title.devices')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 2"
        :name="$t('cloud.title.install')" icon="cil-pen-nib" route="/install"
      >
        <CSidebarNavItem to="/install/organizations" :name="$t('cloud.title.organization')" />
        <CSidebarNavItem to="/install/branches" :name="$t('cloud.title.branch')" />
        <CSidebarNavItem to="/install/users" :name="$t('cloud.title.user')" />
        <CSidebarNavItem to="/install/devices" :name="$t('cloud.title.device')" />
      </CSidebarNavDropdown>

      <!-- <CSidebarNavItem to="/device-portal" icon="cil-storage" :name="$t('cloud.title.device_portal')" /> -->
      <CSidebarNavItem v-if="myAuthorizationLevel.level < 10" to="/log" :name="$t('cloud.title.log')" icon="cil-notes" />
      <!-- <CSidebarNavTitle v-if="myAuthorizationLevel.level <= 20 && org">{{$t('cloud.title.management')}}</CSidebarNavTitle> -->
      <!-- <CSidebarNavItem v-if="myAuthorizationLevel.level <= 20 && org" href="/#/device-portal" :name="$t('cloud.title.device_portal')" icon="cil-storage" /> -->
      <CSidebarNavItem v-if="myAuthorizationLevel.level < 10" to="/kisa-verification" name="시스템 연동성 검증" icon="cil-caret-right" />
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TheSidebar',
  computed: {
    ...mapState([
      'capability',
      'sidebarShow',
      'sidebarMinimize'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    show () {
      return this.sidebarShow 
    },
    minimize () {
      return this.sidebarMinimize 
    },
    org() {
      if (!this.capability) return null;
      return this.capability.organization.id;
    },
    apps() {
      if (!this.capability) return [];
      if (!this.capability.organization.apps) return [];
      return this.capability.organization.apps;
    },
    apps_user_privilidge() {
      if (!this.capability) return false;
      if (!this.capability.user_profile) return false;
      return this.capability.user_profile.auth_level<100;
    },
    is_any_app() {
      if (!this.capability) return false;
      if (this.capability.user_profile.auth_level>=100) return false;
      if (!this.capability.organization.apps) return false;
      let apps_all = this.$utility.copy(this.capability.organization.apps);
      let index = apps_all.indexOf('alarm_console');
      if (index >= 0) {
        apps_all.splice(index, 1);
      }
      if (apps_all.length <= 0) return false;
      return true;
    }
  }
}
</script>
