<template>
  <div>
    <!-- <UserForm 
      ref="FormModal"
      @payload="onUserProfileEditPayload"
      title="UserProfile"
      type="UserProfile"
      >
    </UserForm> -->
    
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <span class="mr-2">{{ (capability && capability.user_profile.email) || '' }}</span>
        <div class="c-avatar">
          <img
            :src="(capability && capability.user_profile.image_signed_url) || '/img/default_user.png'"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $t('cloud.title.account') }}</strong>
    </CDropdownHeader>
    <!-- <CDropdownItem disabled>
      <CIcon name="cil-bell"/> {{ $t('cloud.title.alarm') }}
      <CBadge color="info" class="mfs-auto">3</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownItem disabled>
      <CIcon name="cil-envelope-open" /> {{ $t('cloud.title.messages') }}
    </CDropdownItem> -->
    <!-- <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>{{ $t('cloud.title.settings') }}</strong>
    </CDropdownHeader> -->
    <CDropdownItem @click="$router.push('/pages/profile');">
      <CIcon name="cil-user" /> {{ $t('cloud.title.profile') }}
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/pages/settings');">
      <CIcon name="cil-settings" /> {{ $t('cloud.title.settings') }}
    </CDropdownItem>
    <CDropdownItem disabled>
      <CIcon name="cil-dollar" /> {{ $t('cloud.title.payments') }}
      <!-- <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge> -->
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="ReqLogout">
      <CIcon name="cil-lock-locked" /> {{ $t('cloud.title.logout') }}
    </CDropdownItem>
  </CDropdown>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'TheHeaderDropdownAccnt',
  computed: {
    ...mapState([
      'capability'
    ]),
    ...mapActions([
      'refreshCapability'
    ])
  },
  components:{
    // UserForm
  },
  data(){
    return {
    }
  },
  methods: {
    ReqLogout: function () {
      console.log('ReqLogout');
      this.$api.Logout(() => {
        this.$ws.finalize();
        this.$store.commit('set', ['capability', null]);
        this.$router.push('/pages/login');
      }, (error) => {
        console.error(error);
      })
    },

    // openUserForm(){
    //   this.$refs.FormModal.open();
    // },
    // onUserProfileEditPayload(payload){
    //   axios.put(`/api/users/${this.capability.user_profile.id}/`, payload)
    //     .then(result => {
    //       this.toast_show = true;
    //       this.toast_msg = `Modification was successful`;
    //       this.toast_index++;
    //       this.toast_color = 'success'
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       this.toast_show = true;
    //       this.toast_msg = `Failed: ${error.response.data.error}`;
    //       this.toast_index++;
    //       this.toast_color = 'danger';
    //     })
    // },

  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>